<template>
  <div>
    <div v-if="!isEditable" class="d-flex">
      <span class="w-75">
        {{ warehouseData.name }}
      </span>
      <p-icon role="button" name="bi-pencil-square" class="text-primary mx-half" @click.native="isEditable = true" />
    </div>
    <div v-else class="d-flex align-items-center">
      <input v-model="warehouseName" class="form-control w-75" type="text">
      <p-icon role="button" name="feather-save" size="24px" class="text-primary mx-half" @click.native="updateWarehouseName" />
    </div>
  </div>
</template>

<script>
import warehouseService from '@/http/requests/warehouse/warehouseService'

export default {
  props: {
    warehouseData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isEditable: false,
      warehouseName: '',
    }
  },
  mounted() {
    this.warehouseName = this.warehouseData.name
  },
  methods: {
    async updateWarehouseName() {
      await warehouseService.updateName(this.warehouseData.id, this.warehouseName)
      this.isEditable = false
      this.$emit('emit-name-updated')
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
